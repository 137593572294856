import React from 'react';
import evologo from '../Navbar/evoload-logo.svg';

import twitterIcon from './twitter-icon.svg';
import discordIcon from './discord-icon.svg';
import telegramIcon from './telegram-icon.svg';
import youtubeIcon from './youtube-icon.svg';

export const Footer = () => {
  return (
    <footer>
      <div className='container'>
        <div className="row">
          <div className="footer-inner col-12">
            <a href="/"><img src={evologo} alt="Evoload" className='evo-logo' /></a>
            <p>2023 Evoload © All rights are reserved.</p>
            <ul className='social-inline'>
              <li><a href="https://twitter.com/evoload" target="_blank"><img src={twitterIcon} alt='Twitter'/></a></li>
              <li><a href="https://t.me/evoloadofficialEN" target="_blank"><img src={telegramIcon}  alt='Telegram'/></a></li>
              <li><a href="https://discord.com/invite/Dka6HAZzYH" target="_blank"><img src={discordIcon}  alt='Discord'/></a></li>
              <li><a href="https://www.youtube.com/channel/UCaGw0SwH9TE6Pe6KFJt7Wpg" target="_blank"><img src={youtubeIcon}  alt='Youtube'/></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
