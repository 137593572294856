import { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Progress } from 'antd';
import { Row, Col } from 'react-bootstrap';
import {
  AbiRegistry,
  Address,
  AddressValue,
  ArgSerializer,
  BytesValue,
  ContractFunction,
  Query,
  ResultsParser,
  SmartContract,
  SmartContractAbi,
  TypedValue
} from '@multiversx/sdk-core/out';
import {
  MAX_REFERRAL_COUNT,
  TIMEOUT,
  VESTING_CONTRACT_ADDRESS,
  VESTING_CONTRACT_NAME,
  VESTING_TOKEN_DECIMAL
} from 'config';
import {
  useGetAccountInfo,
  useGetNetworkConfig,
  useGetPendingTransactions
} from '@multiversx/sdk-dapp/hooks';
import {
  ApiNetworkProvider,
  ProxyNetworkProvider
} from '@multiversx/sdk-network-providers/out';
import { convertWeiToEgld } from 'utils/convert';
import scAbi from 'abi/dapp-sc.abi.json';
import { LinkOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';
import './index.scss';
import EgldLogo from '../../assets/logos/egld-logo.png';
import EvldLogo from '../../assets/logos/evld-logo.svg';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import Moment from 'react-moment';
import { v4 as uuid } from 'uuid';

export const Referral = () => {
  const { network } = useGetNetworkConfig();
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const { hasPendingTransactions } = useGetPendingTransactions();
  const networkProvider = new ApiNetworkProvider(network.apiAddress);

  const lastPath = window.location.pathname;
  const referralId = lastPath.split('/')[2];

  const fullPath = `${window.location.href}`;
  const paths = fullPath.split('/');
  const mainPath = paths[0] + '//' + paths[2];
  console.log(fullPath, mainPath);

  const [copied, setCopied] = useState(false);
  const onCopyToClipboard = async () => {
    const link = mainPath + '/sale/' + refLink;
    // console.log(link);
    await navigator.clipboard.writeText(link);
    setCopied(true);
  };

  // load smart contract abi and parse it to SmartContract object for tx
  const [contractInteractor, setContractInteractor] = useState<any>(undefined);
  useEffect(() => {
    const ABI = scAbi as any;
    // console.log(ABI);
    (async () => {
      const abiRegistry = AbiRegistry.create(ABI);
      const abi = new SmartContractAbi(abiRegistry, [VESTING_CONTRACT_NAME]);
      const contract = new SmartContract({
        address: new Address(VESTING_CONTRACT_ADDRESS),
        abi: abi
      });
      setContractInteractor(contract);
    })();
  }, []); // [] makes useEffect run once

  const [refLink, setRefLink] = useState('');
  const [totalVestingAmount, setTotalVestingAmount] = useState(0);
  const [claimedAmount, setClaimedAmount] = useState(0);
  const [claimableAmount, setClaimableAmount] = useState(0);
  const [nextReclaimableTimestamp, setNextReclaimableTimestamp] = useState(0);

  const [referralStatus, setReferralStatus] = useState<any>();
  useEffect(() => {
    (async () => {
      if (!contractInteractor || !isLoggedIn) return;

      const query = contractInteractor.createQuery({
        func: new ContractFunction('getReferralState'),
        args: [new AddressValue(new Address(address))]
      });
      const resultsParser = new ResultsParser();
      const response = await networkProvider.queryContract(query);
      const endpointDefinition =
        contractInteractor.getEndpoint('getReferralState');
      const res = resultsParser.parseQueryResponse(
        response,
        endpointDefinition
      );
      const value = res.firstValue?.valueOf();

      const referral = {
        referral_id: value.referral_id.toString(),
        referral_address: value.referral_address.toString(),
        referral_vesting_amount: convertWeiToEgld(
          value.referral_vesting_amount.toNumber(),
          VESTING_TOKEN_DECIMAL
        ),
        referral_vesting_count: value.referral_vesting_count.toNumber(),
        referral_claimed_amount: convertWeiToEgld(
          value.referral_claimed_amount.toNumber(),
          VESTING_TOKEN_DECIMAL
        ),
        referral_claimable_amount: convertWeiToEgld(
          value.referral_claimable_amount.toNumber(),
          VESTING_TOKEN_DECIMAL
        ),
        referral_next_reclaimalbe_tiemstamp:
          value.referral_next_reclaimalbe_tiemstamp.toNumber()
      };

      // console.log(referral);
      setRefLink(referral.referral_id);
      if (referral.referral_id != '') {
        setTotalVestingAmount(referral.referral_vesting_amount);
        setClaimedAmount(referral.referral_claimed_amount);
        setClaimableAmount(referral.referral_claimable_amount);
        setNextReclaimableTimestamp(
          referral.referral_next_reclaimalbe_tiemstamp
        );
      }
      setReferralStatus(referral);
    })();
  }, [contractInteractor, hasPendingTransactions]);

  const [referrals, setReferrals] = useState<any>();
  useEffect(() => {
    (async () => {
      if (!contractInteractor) return;

      const query = contractInteractor.createQuery({
        func: new ContractFunction('getReferralStates')
      });
      const resultsParser = new ResultsParser();
      const response = await networkProvider.queryContract(query);
      const endpointDefinition =
        contractInteractor.getEndpoint('getReferralStates');
      const res = resultsParser.parseQueryResponse(
        response,
        endpointDefinition
      );
      const value = res.firstValue?.valueOf();

      const referrals: any = [];
      value.map((item: any) => {
        const referral = {
          referral_id: item.referral_id.toString(),
          referral_address: item.referral_address.toString(),
          referral_vesting_amount: convertWeiToEgld(
            item.referral_vesting_amount.toNumber() * 21,
            VESTING_TOKEN_DECIMAL
          ),
          referral_vesting_count: item.referral_vesting_count.toNumber()
        };
        referrals.push(referral);
      });

      // console.log(referrals);
      referrals.sort((a: any, b: any) =>
        a.referral_vesting_amount > b.referral_vesting_amount ? -1 : 1
      );
      let count = MAX_REFERRAL_COUNT;
      if (MAX_REFERRAL_COUNT > referrals.length) {
        count = referrals.length;
      }

      const data: any = [];
      for (let i = 0; i < count; i++) {
        data.push(referrals[i]);
      }
      setReferrals(data);
    })();
  }, [contractInteractor, hasPendingTransactions]);

  // useEffect(() => {
  //   if (hasPendingTransactions) return;

  //   if (isLoggedIn) {
  //     (async () => {
  //       // token balance in sc
  //       const query = new Query({
  //         address: new Address(VESTING_CONTRACT_ADDRESS),
  //         func: new ContractFunction('getUserReferralId'),
  //         args: [new AddressValue(new Address(address))],
  //       });
  //       const proxy = new ProxyNetworkProvider(network.apiAddress, { timeout: TIMEOUT });

  //       try {
  //         const { returnData } = await proxy.queryContract(query);
  //         const [encoded] = returnData;
  //         if (encoded == undefined || encoded == '') {
  //           // console.log('getUserReferralId: ');
  //           setRefLink('');
  //         } else {
  //           const decoded = Buffer.from(encoded, 'base64').toString();
  //           // console.log('getUserReferralId: ', decoded);
  //           setRefLink(decoded);
  //         }
  //       } catch (e: any) {
  //         console.error('Unable to call VM query', e);
  //       }
  //     })();
  //   }
  // }, [hasPendingTransactions]);

  // useEffect(() => {
  //   if (hasPendingTransactions) return;

  //   if (refLink.length > 0) {
  //     (async () => {
  //       // token balance in sc
  //       const query = new Query({
  //         address: new Address(VESTING_CONTRACT_ADDRESS),
  //         func: new ContractFunction('getReferralVestingAmountAll'),
  //         args: [BytesValue.fromUTF8(refLink)],
  //       });
  //       const proxy = new ProxyNetworkProvider(network.apiAddress, { timeout: TIMEOUT });

  //       try {
  //         const { returnData } = await proxy.queryContract(query);
  //         const [encoded] = returnData;
  //         if (encoded == undefined || encoded == '') {
  //           setTotalVestingAmount(0);
  //         } else {
  //           const decoded = Buffer.from(encoded, 'base64').toString('hex');
  //           const value = convertWeiToEgld(parseInt(decoded, 16), VESTING_TOKEN_DECIMAL);
  //           console.log('value: ', value);
  //           setTotalVestingAmount(value);
  //         }
  //       } catch (e: any) {
  //         console.error('Unable to call VM query', e);
  //       }
  //     })();

  //     (async () => {
  //       const query = new Query({
  //         address: new Address(VESTING_CONTRACT_ADDRESS),
  //         func: new ContractFunction('getReferralClaimedAmount'),
  //         args: [BytesValue.fromUTF8(refLink)],
  //       });
  //       const proxy = new ProxyNetworkProvider(network.apiAddress, { timeout: TIMEOUT });

  //       try {
  //         const { returnData } = await proxy.queryContract(query);
  //         const [encoded] = returnData;
  //         if (encoded == undefined || encoded == '') {
  //           setClaimedAmount(0);
  //         } else {
  //           const decoded = Buffer.from(encoded, 'base64').toString('hex');
  //           const value = convertWeiToEgld(parseInt(decoded, 16), VESTING_TOKEN_DECIMAL);
  //           console.log('value: ', value);
  //           setClaimedAmount(value);
  //         }
  //       } catch (e: any) {
  //         console.error('Unable to call VM query', e);
  //       }
  //     })();

  //     (async () => {
  //       const query = new Query({
  //         address: new Address(VESTING_CONTRACT_ADDRESS),
  //         func: new ContractFunction('getReferralReclaimableVestingAmount'),
  //         args: [new AddressValue(new Address(address))],
  //       });
  //       const proxy = new ProxyNetworkProvider(network.apiAddress, { timeout: TIMEOUT });

  //       try {
  //         const { returnData } = await proxy.queryContract(query);
  //         const [encoded] = returnData;
  //         if (encoded == undefined || encoded == '') {
  //           setClaimableAmount(0);
  //         } else {
  //           const decoded = Buffer.from(encoded, 'base64').toString('hex');
  //           const value = convertWeiToEgld(parseInt(decoded, 16), VESTING_TOKEN_DECIMAL);
  //           console.log('value: ', value);
  //           setClaimableAmount(value);
  //         }
  //       } catch (e: any) {
  //         console.error('Unable to call VM query', e);
  //       }
  //     })();

  //     (async () => {
  //       const query = new Query({
  //         address: new Address(VESTING_CONTRACT_ADDRESS),
  //         func: new ContractFunction('getReferralNextReclaimableTimestamp'),
  //         args: [new AddressValue(new Address(address))],
  //       });
  //       const proxy = new ProxyNetworkProvider(network.apiAddress, { timeout: TIMEOUT });

  //       try {
  //         const { returnData } = await proxy.queryContract(query);
  //         const [encoded] = returnData;
  //         if (encoded == undefined || encoded == '') {
  //           setClaimableAmount(0);
  //         } else {
  //           const decoded = Buffer.from(encoded, 'base64').toString('hex');
  //           const value = parseInt(decoded, 16);
  //           setNextReclaimableTimestamp(value);
  //         }
  //       } catch (e: any) {
  //         console.error('Unable to call VM query', e);
  //       }
  //     })();
  //   }
  // }, [hasPendingTransactions, refLink]);

  const handleClaim = async () => {
    const claimTransaction = {
      data: 'claimByReferral',
      gasLimit: 60000000,
      receiver: VESTING_CONTRACT_ADDRESS,
      value: 0
    };

    await refreshAccount();
    await sendTransactions({
      transactions: claimTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Claim transaction',
        errorMessage: 'An error has occured during Claim',
        successMessage: 'Claim transaction successful'
      },
      redirectAfterSign: false
    });
  };

  const handleCreate = async () => {
    if (!isLoggedIn) return;

    const uniqueId = uuid();
    const smallId = uniqueId.slice(0, 8);
    // console.log('smallId: ', smallId);
    const args: TypedValue[] = [BytesValue.fromUTF8(smallId)];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `createReferral@${argumentsString}`;

    const tx = {
      value: 0,
      data: data.toString(),
      receiver: VESTING_CONTRACT_ADDRESS,
      gasLimit: 10000000
    };
    await refreshAccount();
    await sendTransactions({
      transactions: tx
    });
  };

  return (
    <div className='container'>
      <div className='main-banner no-overlay'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h3>EVLD</h3>
              <h1>REFERRAL</h1>
            </div>
          </div>
        </div>
      </div>

      <div className='general-content stake-statistics-wrapper'>
        <div className='gc-corners'>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tl'>
            <path
              className='st0'
              fill='#333C54'
              d='M0,45V12.2C0,5.5,5.4,0,12.2,0h124c6.5,0,12.7,3,16.7,8.2L181,45H0z'
            />
          </svg>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tr'>
            <path
              className='st0'
              fill='#333C54'
              d='M181,45V12.2C181,5.5,175.6,0,168.8,0l-124,0c-6.5,0-12.7,3-16.7,8.2L0,45H181z'
            />
          </svg>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-bl'>
            <path
              className='st0'
              fill='#333C54'
              d='M0,0l0,32.8C0,39.5,5.4,45,12.2,45h124c6.5,0,12.7-3,16.7-8.2L181,0L0,0z'
            />
          </svg>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-br'>
            <path
              className='st0'
              fill='#333C54'
              d='M181,0v32.8c0,6.7-5.4,12.2-12.2,12.2h-124c-6.5,0-12.7-3-16.7-8.2L0,0H181z'
            />
          </svg>
        </div>

        <div className='cta-slim cta-fill cta-create-link mt-0'>
          {isLoggedIn ? (
            refLink.length > 0 ? (
              <div className='w-100'>
                <div className='d-lg-flex align-items-center'>
                  <div className='ml-0 mr-auto'>
                    <h6 id='referralid'>
                      <i className='fa fa-solid fa-user-plus mr-1'></i> My
                      Referral Link:
                      <br className='mt-1 mt-lg-0 d-lg-none' />{' '}
                      <b className='word-break'>
                        {mainPath + '/sale/' + refLink}
                      </b>{' '}
                      <br />
                    </h6>
                    <h6 className='d-lg-flex align-items-center mt-3 mt-lg-1 mb-4 mb-lg-0'>
                      <i className='fa fa-solid fa-chart-line mr-1 mr-lg-3'></i>{' '}
                      My Stats:{' '}
                      <b>
                        {referralStatus && (
                          <div className='ml-lg-2'>
                            {referralStatus.referral_vesting_count} Users -
                            Total EVLD {totalVestingAmount}
                          </div>
                        )}
                      </b>
                    </h6>
                  </div>

                  <button
                    className='secondary-btn ml-auto mr-0 mt-3 my-lg-auto'
                    onClick={onCopyToClipboard}
                  >
                    <span className='btn-name'>
                      {copied ? 'Link Copied' : 'Copy Link'}
                    </span>
                    <svg
                      width='17'
                      height='10'
                      viewBox='0 0 17 10'
                      className='btn-icon'
                    >
                      <path
                        d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                        fill='white'
                      />
                    </svg>
                    <svg
                      x='0px'
                      y='0px'
                      viewBox='0 0 66 53'
                      className='btn-deco'
                    >
                      <g>
                        <g>
                          <path
                            className='st0'
                            fill='#005DFF'
                            d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                          />
                          <path
                            className='st1'
                            fill='#005DFF'
                            d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
                        			c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                          />
                        </g>
                        <path
                          className='st2'
                          fill='#3F4B6B'
                          d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                        />
                      </g>
                    </svg>
                    <span className='btn-shadow'></span>
                  </button>
                </div>
              </div>
            ) : (
              <div className='w-100 d-inline-block d-lg-flex align-items-center'>
                <div className='ms-0 mr-auto'>
                  <h4>Please create and share referral link</h4>
                  <p className='mt-2 mb-2 mb-lg-0 mt-lg-0'>
                    Using your referral link, you will gain 5% from each
                    purchase of EVLD!
                  </p>
                </div>

                <button
                  className='secondary-btn ml-auto mr-0 mt-3 my-lg-auto'
                  onClick={handleCreate}
                >
                  <span className='btn-name'>CREATE LINK</span>
                  <svg
                    width='17'
                    height='10'
                    viewBox='0 0 17 10'
                    className='btn-icon'
                  >
                    <path
                      d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                      fill='white'
                    />
                  </svg>
                  <svg x='0px' y='0px' viewBox='0 0 66 53' className='btn-deco'>
                    <g>
                      <g>
                        <path
                          className='st0'
                          fill='#005DFF'
                          d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                        />
                        <path
                          className='st1'
                          fill='#005DFF'
                          d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
                        			c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                        />
                      </g>
                      <path
                        className='st2'
                        fill='#3F4B6B'
                        d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                      />
                    </g>
                  </svg>
                  <span className='btn-shadow'></span>
                </button>
              </div>
            )
          ) : (
            <div className='w-100 text-center w-100'>
              <h6 className='text-uppercase text-center w-100'>
                <b className='text-uppercase text-center w-100'>
                  Please connect your wallet and share referral link with your
                  friend.
                </b>
              </h6>
            </div>
          )}
        </div>

        <div className='w-100 mt-5'>
          <div
            className='card bg-dark swap-form stake-body d-flex flex-row'
            aria-controls='positions-list'
            aria-expanded='false'
          >
            <div className='card-body d-flex justify-content-between stake-card-main'>
              <div className='d-flex flex-row align-items-center stake-farm-header'>
                <div className='p-0'>
                  <img
                    src={EvldLogo}
                    className='p-0 token-image'
                    style={{ width: '48px', height: '48px' }}
                  ></img>
                </div>
                <div className='ml-3'>
                  <h6>
                    <b>Total Reward</b>
                  </h6>
                  <div className='dotted-underline'>
                    <div>
                      <h6>
                        <b>{totalVestingAmount.toFixed(0)} $EVLD</b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center arrow-column'></div>
              </div>
              <div className='d-flex stake-farm-body'>
                <div className='key'>
                  <h6>Received</h6>
                  <div className='value'>
                    <h6>
                      <b>{claimedAmount.toFixed(0)} $EVLD</b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className='d-flex stake-farm-body'>
                <div className='key'>
                  <h6>Claimable</h6>
                  <div className='value'>
                    <h6>
                      <b>{claimableAmount.toFixed(0)} $EVLD</b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className='d-flex stake-farm-body'>
                <div className='key'>
                  <h6>Next Unlock</h6>
                  <div className='value'>
                    <h6>
                      <b>
                        {nextReclaimableTimestamp != 0 && (
                          <Moment format='MM.DD.YY hh:mm'>
                            {
                              new Date(
                                parseInt(nextReclaimableTimestamp + '000')
                              )
                            }
                          </Moment>
                        )}
                      </b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className='d-flex stake-farm-body'>
                <div className='key'>
                  <h6>Remaining</h6>
                  <div className='value'>
                    {' '}
                    <h6>
                      <b>
                        {(totalVestingAmount - claimedAmount).toFixed(0)} $EVLD
                      </b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className='d-flex stake-buttons'>
                {isLoggedIn ? (
                  <button
                    className='main-btn connect-btn w-100'
                    disabled={claimableAmount <= 0}
                    onClick={handleClaim}
                  >
                    <span className='btn-name'>CLAIM</span>
                    <svg
                      width='17'
                      height='10'
                      viewBox='0 0 17 10'
                      className='btn-icon'
                    >
                      <path
                        d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                        fill='white'
                      />
                    </svg>
                    <svg
                      x='0px'
                      y='0px'
                      viewBox='0 0 66 53'
                      className='btn-deco'
                    >
                      <g>
                        <g>
                          <path
                            className='st0'
                            fill='#005DFF'
                            d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                          />
                          <path
                            className='st1'
                            fill='#005DFF'
                            d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                          />
                        </g>
                        <path
                          className='st2'
                          fill='#3F4B6B'
                          d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                        />
                      </g>
                    </svg>
                    <span className='btn-shadow'></span>
                  </button>
                ) : (
                  <Link
                    className='main-btn connect-btn w-100'
                    to={
                      referralId && referralId.length > 0
                        ? `/unlock/${referralId}`
                        : '/unlock'
                    }
                  >
                    <span className='btn-name'>CONNECT</span>
                    <svg
                      width='17'
                      height='10'
                      viewBox='0 0 17 10'
                      className='btn-icon'
                    >
                      <path
                        d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                        fill='white'
                      />
                    </svg>
                    <svg
                      x='0px'
                      y='0px'
                      viewBox='0 0 66 53'
                      className='btn-deco'
                    >
                      <g>
                        <g>
                          <path
                            className='st0'
                            fill='#005DFF'
                            d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                          />
                          <path
                            className='st1'
                            fill='#005DFF'
                            d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                          />
                        </g>
                        <path
                          className='st2'
                          fill='#3F4B6B'
                          d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                        />
                      </g>
                    </svg>
                    <span className='btn-shadow'></span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='general-content stake-statistics-wrapper referred-users-wrapper'>
        <div className='gc-corners'>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tl'>
            <path
              className='st0'
              fill='#333C54'
              d='M0,45V12.2C0,5.5,5.4,0,12.2,0h124c6.5,0,12.7,3,16.7,8.2L181,45H0z'
            />
          </svg>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tr'>
            <path
              className='st0'
              fill='#333C54'
              d='M181,45V12.2C181,5.5,175.6,0,168.8,0l-124,0c-6.5,0-12.7,3-16.7,8.2L0,45H181z'
            />
          </svg>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-bl'>
            <path
              className='st0'
              fill='#333C54'
              d='M0,0l0,32.8C0,39.5,5.4,45,12.2,45h124c6.5,0,12.7-3,16.7-8.2L181,0L0,0z'
            />
          </svg>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-br'>
            <path
              className='st0'
              fill='#333C54'
              d='M181,0v32.8c0,6.7-5.4,12.2-12.2,12.2h-124c-6.5,0-12.7-3-16.7-8.2L0,0H181z'
            />
          </svg>
        </div>
        <h6 className='d-flex justify-content-around mb-3 mt-n1 mt-lg-n4'>
          <b>
            Leaderboard <i className='fa-solid fa-level-down-alt ml-2'></i>
          </b>{' '}
          <span className='ml-lg-auto mr-lg-0'>
            <b>
              More details{' '}
              <a href='#'>
                {' '}
                here
              </a>
            </b>
          </span>
        </h6>

        <div className='referral-stats'>
          <div className='stats-data'>
            {referrals?.map((item: any, index: number) => {
              if (item.referral_vesting_count)
                return (
                  <h6 key={index}>
                    {' '}
                    <span className='ml-lg-0 mr-lg-2'>
                      <b>
                        {index + 1}.{' '}
                        {`${item.referral_address.substring(
                          0,
                          3
                        )}...${item.referral_address.slice(-4)}`}{' '}
                      </b>{' '}
                    </span>
                    <span className='ml-lg-auto mr-lg-0'>
                      {item.referral_vesting_count} users - Total EVLD{' '}
                      <b>{item.referral_vesting_amount}</b>
                    </span>
                  </h6>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
