import React, { useState } from 'react';
import evologo from './evoload-logo.svg';
import nsleft from './ns-left.svg';
import nsright from './ns-right.svg';

import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import { Navbar as BsNavbar, NavItem, Nav, Container } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';

import './index.scss';

export const Navbar = () => {
  const navigate = useNavigate();
  const isLoggedIn = useGetIsLoggedIn();

  const lastPath = window.location.pathname;
  const referralId = lastPath.split('/')[2];

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
    console.log(`logged out from:${window.location.origin}/unlock`);
  };
  const handleLogin = () => {
    if (referralId && referralId.length > 0) {
      navigate(`/unlock/${referralId}`);
    } else {
      navigate('/unlock');
    }
  };
  const [expanded, setExpanded] = useState(false);

  return (
    <BsNavbar
      collapseOnSelect={true}
      expanded={expanded}
      expand='lg'
      variant='light'
    >
      <Container className='custom-navbar-container' fluid>
        <BsNavbar.Brand>
          <NavLink
            className='d-flex align-items-center navbar-brand mr-0'
            to={
              referralId && referralId.length > 0
                ? `/home/${referralId}`
                : '/home'
            }
          >
            <img src={evologo} alt='Evoload' />
          </NavLink>
          <span className='change-theme'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12 10.999C13.437 11.437 14.562 12.563 14.999 14C15.439 12.563 16.564 11.438 18 11C16.564 10.561 15.439 9.437 14.999 8C14.562 9.436 13.437 10.561 12 10.999ZM20.001 11C20.959 11.293 21.708 12.042 22.001 13.001C22.292 12.042 23.043 11.292 24 11C23.043 10.708 22.293 9.958 22 9C21.707 9.958 20.958 10.708 20.001 11ZM19.001 2C18.564 3.437 17.438 4.562 16.003 5.001C17.441 5.441 18.564 6.565 19.004 8.003C19.441 6.565 20.567 5.44 22 5.001C20.567 4.564 19.441 3.437 19.001 2ZM12 24C5.383 24 0 18.617 0 12C0 5.383 5.383 0 12 0C13.894 0 15.63 0.497 17.37 1.179C14.422 1.683 8 4.445 8 12C8 19.454 13.917 22.208 17.37 22.821C15.87 23.667 13.894 24 12 24Z'
                fill='white'
              />
            </svg>
          </span>
          {isLoggedIn ? (
            <div
              className='connect-wallet secondary-btn'
              onClick={handleLogout}
            >
              <span className='btn-name'>LOGOUT</span>
              <svg
                width='17'
                height='10'
                viewBox='0 0 17 10'
                className='btn-icon'
              >
                <path
                  d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                  fill='white'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 66 53' className='btn-deco'>
                <g>
                  <g>
                    <path
                      className='st0'
                      fill='#005DFF'
                      d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                    />
                    <path
                      className='st1'
                      fill='#005DFF'
                      d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                    />
                  </g>
                  <path
                    className='st2'
                    fill='#3F4B6B'
                    d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                  />
                </g>
              </svg>
              <span className='btn-shadow'></span>
            </div>
          ) : (
            <div className='connect-wallet main-btn' onClick={handleLogin}>
              <span className='btn-name'>Connect</span>
              <svg
                width='17'
                height='10'
                viewBox='0 0 17 10'
                className='btn-icon'
              >
                <path
                  d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                  fill='white'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 66 53' className='btn-deco'>
                <g>
                  <g>
                    <path
                      className='st0'
                      fill='#005DFF'
                      d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                    />
                    <path
                      className='st1'
                      fill='#005DFF'
                      d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
                			c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                    />
                  </g>
                  <path
                    className='st2'
                    fill='#3F4B6B'
                    d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                  />
                </g>
              </svg>
              <span className='btn-shadow'></span>
            </div>
          )}
        </BsNavbar.Brand>
        <BsNavbar.Toggle
          aria-controls='responsive-navbar-nav'
          onClick={() => setExpanded(!expanded)}
        />
        <BsNavbar.Collapse id='responsive-navbar-nav' className='nav-menu-wrap'>
          <Nav id='responsive-navbar-nav' className='ml-auto mr-auto'>
            <NavLink
              onClick={() => setExpanded(false)}
              to={
                referralId && referralId.length > 0
                  ? `/home/${referralId}`
                  : '/home'
              }
            >
              HOME
            </NavLink>
            {/* <NavLink
              onClick={() => setExpanded(false)}
              to={
                referralId && referralId.length > 0
                  ? `/sale/${referralId}`
                  : '/sale'
              }
            >
              SALE
            </NavLink> */}
            <NavLink
              onClick={() => setExpanded(false)}
              to={
                referralId && referralId.length > 0
                  ? `/claim/${referralId}`
                  : '/claim'
              }
            >
              CLAIM
            </NavLink>
            {/* <NavLink
              onClick={() => setExpanded(false)}
              to={
                referralId && referralId.length > 0
                  ? `/referral/${referralId}`
                  : '/referral'
              }
            >
              Referral
            </NavLink> */}
            <NavLink
              onClick={() => setExpanded(false)}
              to={
                referralId && referralId.length > 0
                  ? `/myaccount/${referralId}`
                  : '/myaccount'
              }
            >
              MY ACCOUNT
            </NavLink>
            <a href={'https://staking.evoload.co/'}>STAKING</a>
            <a href={'https://evoload.co/'}>WEBSITE</a>
            <div className='nav-shapes'>
              <img className='ns-left' src={nsleft} alt='Evoload' />
              <img className='ns-right' src={nsright} alt='Evoload' />
            </div>
          </Nav>
          {isLoggedIn ? (
            <div
              className='connect-wallet secondary-btn'
              onClick={handleLogout}
            >
              <span className='btn-name'>LOGOUT</span>
              <svg
                width='17'
                height='10'
                viewBox='0 0 17 10'
                className='btn-icon'
              >
                <path
                  d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                  fill='white'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 66 53' className='btn-deco'>
                <g>
                  <g>
                    <path
                      className='st0'
                      fill='#005DFF'
                      d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                    />
                    <path
                      className='st1'
                      fill='#005DFF'
                      d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                    />
                  </g>
                  <path
                    className='st2'
                    fill='#3F4B6B'
                    d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                  />
                </g>
              </svg>
              <span className='btn-shadow'></span>
            </div>
          ) : (
            <div className='connect-wallet main-btn' onClick={handleLogin}>
              <span className='btn-name'>Connect</span>
              <svg
                width='17'
                height='10'
                viewBox='0 0 17 10'
                className='btn-icon'
              >
                <path
                  d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                  fill='white'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 66 53' className='btn-deco'>
                <g>
                  <g>
                    <path
                      className='st0'
                      fill='#005DFF'
                      d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                    />
                    <path
                      className='st1'
                      fill='#005DFF'
                      d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
                			c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                    />
                  </g>
                  <path
                    className='st2'
                    fill='#3F4B6B'
                    d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                  />
                </g>
              </svg>
              <span className='btn-shadow'></span>
            </div>
          )}
        </BsNavbar.Collapse>
      </Container>
    </BsNavbar>
  );
};
