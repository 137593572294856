import video from './bg.mp4';
import { useEffect } from 'react';
import lozad from 'lozad';
import { useLocation } from 'react-router-dom';
import graph1 from './graph-1.png';
import graph2 from './graph-2.png';

export const Home = () => {
  const location = useLocation();

  useEffect(() => {
    const observer = lozad();
    observer.observe();
  }, []);

  return (
    <div>
      <div className='main-banner'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h3>WELCOME TO</h3>
              <h1>EVOLOAD PUBLIC SALE</h1>
            </div>
          </div>
        </div>
        <video className='lozad' autoPlay playsInline loop>
          <source data-src={video} type='video/mp4' />
          <source data-src={video} type='video/ogg' />
        </video>
      </div>

      <div className='home-content'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='general-content'>
                <div className='gc-corners'>
                  <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tl'>
                    <path
                      className='st0'
                      fill='#333C54'
                      d='M0,45V12.2C0,5.5,5.4,0,12.2,0h124c6.5,0,12.7,3,16.7,8.2L181,45H0z'
                    />
                  </svg>
                  <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tr'>
                    <path
                      className='st0'
                      fill='#333C54'
                      d='M181,45V12.2C181,5.5,175.6,0,168.8,0l-124,0c-6.5,0-12.7,3-16.7,8.2L0,45H181z'
                    />
                  </svg>
                  <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-bl'>
                    <path
                      className='st0'
                      fill='#333C54'
                      d='M0,0l0,32.8C0,39.5,5.4,45,12.2,45h124c6.5,0,12.7-3,16.7-8.2L181,0L0,0z'
                    />
                  </svg>
                  <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-br'>
                    <path
                      className='st0'
                      fill='#333C54'
                      d='M181,0v32.8c0,6.7-5.4,12.2-12.2,12.2h-124c-6.5,0-12.7-3-16.7-8.2L0,0H181z'
                    />
                  </svg>
                </div>

                <h3>PUBLIC SALE</h3>

                <h6 className='mt-4'>
                  <b>
                    Read carefully{' '}
                    <i className='fa-solid fa-level-down-alt ml-2'></i>
                  </b>
                </h6>
                <ul className='square-list mt-2'>
                  <li>
                    <h6>🛒 Min buy: 10$ | Max buy: 20.000$</h6>
                  </li>
                  <li>
                    <h6>
                      🛅 Vesting: 25% unlocked, no cliff, then 8 months linear vesting
                      unlock
                    </h6>
                  </li>
                  <li>
                    <h6>
                      ✅ Allowed tokens: $EGLD, $USDC, $ASH, $BHAT, $CRT, $CRU,
                      $RIDE, $QWT, $UTK, $ZPAY
                    </h6>
                  </li>
                  <li>
                    <h6>
                      🆕  <a href='https://launchpad.kommunitas.net/pool/EVLD/PublicCross' target="_blank">
                        Kommunitas
                      </a> & <a href='https://launchpad.spores.app/ino/evoload-ido' target="_blank">
                        Spores Network
                      </a> Launchpads Sold-out
                    </h6>
                  </li>
                  <li>
                    <h6>🆕 Listing on <a href='https://www.mexc.com/' target="_blank">
                        MEXC
                      </a> & <a href='https://xexchange.com/' target="_blank">
                        xExchange
                      </a>.</h6>
                  </li>
               
                 
                </ul>

                <div className='cta-slim mb-lg-5 mb-1'>
                  <h4>BUY EVLD HERE</h4>
                  <a
                    className='main-btn mr-lg-2 ml-lg-auto'
                    href={location.pathname.replace('home', 'sale')}
                  >
                    <span className='btn-name'>BUY WITH MULTIVERSX</span>
                    <svg
                      width='17'
                      height='10'
                      viewBox='0 0 17 10'
                      className='btn-icon'
                    >
                      <path
                        d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                        fill='white'
                      />
                    </svg>
                    <svg
                      x='0px'
                      y='0px'
                      viewBox='0 0 66 53'
                      className='btn-deco'
                    >
                      <g>
                        <g>
                          <path
                            className='st0'
                            fill='#005DFF'
                            d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                          />
                          <path
                            className='st1'
                            fill='#005DFF'
                            d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
                        			c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                          />
                        </g>
                        <path
                          className='st2'
                          fill='#3F4B6B'
                          d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                        />
                      </g>
                    </svg>
                    <span className='btn-shadow'></span>
                  </a>
                  <a
                    className='main-btn ml-lg-2 mr-lg-0'
                    href='https://evoload.presail.com/deals/MlAyznrYsrmMCMdme79wuw?pool_guid=Zc-N38OZbyPbRQnJwDga2w'
                  >
                    <span className='btn-name'>BUY WITH BSC & ETH </span>
                    <svg
                      width='17'
                      height='10'
                      viewBox='0 0 17 10'
                      className='btn-icon'
                    >
                      <path
                        d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                        fill='white'
                      />
                    </svg>
                    <svg
                      x='0px'
                      y='0px'
                      viewBox='0 0 66 53'
                      className='btn-deco'
                    >
                      <g>
                        <g>
                          <path
                            className='st0'
                            fill='#005DFF'
                            d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                          />
                          <path
                            className='st1'
                            fill='#005DFF'
                            d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
                        			c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                          />
                        </g>
                        <path
                          className='st2'
                          fill='#3F4B6B'
                          d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                        />
                      </g>
                    </svg>
                    <span className='btn-shadow'></span>
                  </a>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
