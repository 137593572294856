import React, { PropsWithChildren } from 'react';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { Navigate } from 'react-router-dom';
import { routeNames } from 'routes';

export const AuthRedirectWrapper = ({ children }: PropsWithChildren) => {
  const isLoggedIn = useGetIsLoggedIn();
  const lastPath = window.location.pathname;
  const referralId = lastPath.split('/')[2];

  if (isLoggedIn) {
    return (
      <Navigate
        to={
          referralId && referralId.length > 0 ? `/home/${referralId}` : '/home'
        }
      />
    );
  }

  return <>{children}</>;
};
