import { RouteType } from '@multiversx/sdk-dapp/types';
import { withPageTitle } from './components/PageTitle';

import { Sale } from 'pages/Sale';
import { Claim } from 'pages/Claim';
import { MyAccount } from 'pages/MyAccount';
import { Home } from 'pages/Home';
import { Referral } from 'pages/Referral';

export const routeNames = {
  home: '/home/*',
  sale: '/sale/*',
  claim: '/claim/*',
  referral: '/referral/*',
  unlock: '/unlock/*',
  myaccount: '/myaccount/*'
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.sale,
    title: 'Sale',
    component: Home
  },
  {
    path: routeNames.claim,
    title: 'Claim',
    component: Claim
  },
  {
    path: routeNames.myaccount,
    title: 'MyAccount',
    component: MyAccount
  },
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.referral,
    title: 'Referral',
    component: Referral
  }
];

export const mappedRoutes = routes.map((route) => {
  const title = '';

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});
