import { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Progress } from 'antd';
import { Row, Col } from 'react-bootstrap';
import {
  Address,
  AddressValue,
  ContractFunction,
  Query,
  AbiRegistry,
  SmartContractAbi,
  SmartContract,
  Interaction,
  ResultsParser
} from '@multiversx/sdk-core/out';
import {
  TIMEOUT,
  VESTING_CONTRACT_ADDRESS,
  VESTING_TOKEN_DECIMAL,
  PRIVATE_SALE_CONTRACT_ADDRESS,
  SEED_SALE_CONTRACT_ADDRESS,
  SEED_SALE_CONTRACT2_ADDRESS,
  TOTAL_RELEASE_COUNT,
  RELEASE_TIMESTAMPS_1,
  RELEASE_TIMESTAMPS_2,
  TOKEN_DECIMAL,
  VESTING_CONTRACT_ADDRESS_1
} from 'config';
import {
  useGetAccountInfo,
  useGetNetworkConfig,
  useGetPendingTransactions
} from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import {
  convertWeiToEgld,
  getDaysFromNow,
  getDaysFromNow2
} from 'utils/convert';
import { SECOND_IN_MILLI } from 'utils/const';
import { LinkOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';
import './index.scss';
import EgldLogo from 'assets/logos/egld-logo.png';
import EvldLogo from 'assets/logos/evld-logo.svg';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import Moment from 'react-moment';
import PrivateSeedSaleABI from 'abi/privateSaleABI.json';
import SeedSaleABI from 'abi/seedSale.json';

export const Claim = () => {
  const { network } = useGetNetworkConfig();
  const { address } = useGetAccountInfo();

  const isLoggedIn = Boolean(address);
  const { hasPendingTransactions } = useGetPendingTransactions();

  const lastPath = window.location.pathname;
  const referralId = lastPath.split('/')[2];

  const [totalVestingAmount, setTotalVestingAmount] = useState(0);
  const [claimedAmount, setClaimedAmount] = useState(0);
  const [claimableAmount, setClaimableAmount] = useState(0);
  const [nextReclaimableTimestamp, setNextReclaimableTimestamp] = useState(0);

  //========== Private sale =====
  const [claimablePrivateSale, setClaimablePrivateSale] = useState(0);
  const [lockedPrivateSale, setLockedPrivateSale] = useState(Object({}));
  const [roundsPrivateSale, setRoundsPrivateSale] = useState(0);
  const [nextUnlockPrivateSale, setNextUnlockPrivateSale] = useState(0);
  const [fullUnlockPrivateSale, setFullUnlockPrivateSale] = useState(0);
  const [accountStatePrivate, setAccountStatePrivate] = useState(Object({}));
  const [accountStateSeed, setAccountStateSeed] = useState(Object({}));
  const [accountStateSeed2, setAccountStateSeed2] = useState(Object({}));

  const [daysFromNextUnlock_1, setDaysFromNextUnlock_1] = useState<number>(0);
  const [daysFromFullUnlock_1, setDaysFromFullUnlock_1] = useState<number>(0);
  //==============================

  //=========== New Presale ======
  const [totalVestingAmount1, setTotalVestingAmount1] = useState(0);
  const [claimedAmount1, setClaimedAmount1] = useState(0);
  const [claimableAmount1, setClaimableAmount1] = useState(0);
  const [nextReclaimableTimestamp1, setNextReclaimableTimestamp1] = useState(0);
  //==============================

  useEffect(() => {
    let i;
    const currentTimestamp = new Date().getTime();
    for (i = 0; i < RELEASE_TIMESTAMPS_1.length - 1; i++) {
      if (currentTimestamp < RELEASE_TIMESTAMPS_1[i]) {
        break;
      }
    }
    //console.log('i =>', i);
    setDaysFromNextUnlock_1(RELEASE_TIMESTAMPS_1[i]);
    setDaysFromFullUnlock_1(
      RELEASE_TIMESTAMPS_1[RELEASE_TIMESTAMPS_1.length - 1]
    );
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      //=======Private Sale===========
      (async () => {
        try {
          const abiRegistry = await AbiRegistry.create(PrivateSeedSaleABI);
          const contract = new SmartContract({
            address: new Address(PRIVATE_SALE_CONTRACT_ADDRESS),
            abi: new SmartContractAbi(abiRegistry)
          });

          const args = new Address(address);
          const interaction = contract.methods.getAccountState([args]);

          const proxy = new ProxyNetworkProvider(network.apiAddress, {
            timeout: TIMEOUT
          });

          const query = interaction.check().buildQuery();
          const queryResponse = await proxy.queryContract(query);

          const res = new ResultsParser().parseQueryResponse(
            queryResponse,
            interaction.getEndpoint()
          );

          if (!res || !res.returnCode.isSuccess()) return;

          const value = res.firstValue?.valueOf();

          const boughtAmount_wei_3 = value.field0.toNumber();
          const lockedAmount_wei_3 = value.field1.toNumber();
          const boughtAmount = convertWeiToEgld(
            boughtAmount_wei_3,
            VESTING_TOKEN_DECIMAL
          );
          const lockedAmount = convertWeiToEgld(
            lockedAmount_wei_3,
            VESTING_TOKEN_DECIMAL
          );
          const claimedReleaseCount = value.field2.toNumber();
          const claimableReleaseCount = value.field3.toNumber();
          const claimableAmount =
            (lockedAmount * claimableReleaseCount) / TOTAL_RELEASE_COUNT;

          setAccountStatePrivate({
            boughtAmount,
            lockedAmount,
            claimedReleaseCount,
            claimableReleaseCount,
            claimableAmount
          });
        } catch (e: any) {
          console.error('Unable to call VM query', e);
        }
      })();
      //==============================

      //=======Seed Sale 1===========
      (async () => {
        try {
          const abiRegistry = await AbiRegistry.create(SeedSaleABI);
          const contract = new SmartContract({
            address: new Address(SEED_SALE_CONTRACT_ADDRESS),
            abi: new SmartContractAbi(abiRegistry)
          });

          const args = new Address(address);
          const interaction = contract.methods.getAccountState([args]);

          const proxy = new ProxyNetworkProvider(network.apiAddress, {
            timeout: TIMEOUT
          });

          const query = interaction.check().buildQuery();
          const queryResponse = await proxy.queryContract(query);

          const res = new ResultsParser().parseQueryResponse(
            queryResponse,
            interaction.getEndpoint()
          );

          if (!res || !res.returnCode.isSuccess()) return;

          const value = res.firstValue?.valueOf();

          const initialLockedAmount = convertWeiToEgld(
            value.initial_locked_amount.toNumber(),
            TOKEN_DECIMAL
          );
          const currentLockedAmount = convertWeiToEgld(
            value.current_locked_amount.toNumber(),
            TOKEN_DECIMAL
          );
          const claimedReleaseAmount = convertWeiToEgld(
            value.claimed_release_amount.toNumber(),
            TOKEN_DECIMAL
          );
          const claimableReleaseAmount = convertWeiToEgld(
            value.claimable_release_amount.toNumber(),
            TOKEN_DECIMAL
          );
          const round = value.round.toNumber();
          const nextReleaseTimestamp =
            value.next_release_timestamp.toNumber() * SECOND_IN_MILLI;
          const fullReleaseTimestamp =
            value.full_release_timestamp.toNumber() * SECOND_IN_MILLI;
          setAccountStateSeed({
            initialLockedAmount,
            currentLockedAmount,
            claimedReleaseAmount,
            claimableReleaseAmount,
            nextReleaseTimestamp,
            fullReleaseTimestamp
          });
        } catch (e: any) {
          console.error('Unable to call VM query', e);
        }
      })();
      //==============================

      //=======Seed Sale 2===========
      (async () => {
        try {
          const abiRegistry = await AbiRegistry.create(SeedSaleABI);
          const contract = new SmartContract({
            address: new Address(SEED_SALE_CONTRACT2_ADDRESS),
            abi: new SmartContractAbi(abiRegistry)
          });

          const args = new Address(address);
          const interaction = contract.methods.getAccountState([args]);

          const proxy = new ProxyNetworkProvider(network.apiAddress, {
            timeout: TIMEOUT
          });

          const query = interaction.check().buildQuery();
          const queryResponse = await proxy.queryContract(query);

          const res = new ResultsParser().parseQueryResponse(
            queryResponse,
            interaction.getEndpoint()
          );

          if (!res || !res.returnCode.isSuccess()) return;

          const value = res.firstValue?.valueOf();

          const initialLockedAmount = convertWeiToEgld(
            value.initial_locked_amount.toNumber(),
            TOKEN_DECIMAL
          );
          const currentLockedAmount = convertWeiToEgld(
            value.current_locked_amount.toNumber(),
            TOKEN_DECIMAL
          );
          const claimedReleaseAmount = convertWeiToEgld(
            value.claimed_release_amount.toNumber(),
            TOKEN_DECIMAL
          );
          const claimableReleaseAmount = convertWeiToEgld(
            value.claimable_release_amount.toNumber(),
            TOKEN_DECIMAL
          );
          const round = value.round.toNumber();
          const nextReleaseTimestamp =
            value.next_release_timestamp.toNumber() * SECOND_IN_MILLI;
          const fullReleaseTimestamp =
            value.full_release_timestamp.toNumber() * SECOND_IN_MILLI;
          setAccountStateSeed2({
            initialLockedAmount,
            currentLockedAmount,
            claimedReleaseAmount,
            claimableReleaseAmount,
            nextReleaseTimestamp,
            fullReleaseTimestamp
          });
        } catch (e: any) {
          console.error('Unable to call VM query', e);
        }
      })();
      //==============================

      //=======Presale 1,2,3===========
      (async () => {
        // token balance in sc
        const query = new Query({
          address: new Address(VESTING_CONTRACT_ADDRESS),
          func: new ContractFunction('getUserVestingAmountAll'),
          args: [new AddressValue(new Address(address))]
        });
        const proxy = new ProxyNetworkProvider(network.apiAddress, {
          timeout: TIMEOUT
        });

        try {
          const { returnData } = await proxy.queryContract(query);

          const [encoded] = returnData;
          if (encoded == undefined || encoded == '') {
            setTotalVestingAmount(0);
          } else {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            const value = convertWeiToEgld(
              parseInt(decoded, 16),
              VESTING_TOKEN_DECIMAL
            );
            console.log('value: ', value);
            setTotalVestingAmount(value);
          }
        } catch (e: any) {
          console.error('Unable to call VM query', e);
        }
      })();

      (async () => {
        const query = new Query({
          address: new Address(VESTING_CONTRACT_ADDRESS),
          func: new ContractFunction('getUserClaimedAmount'),
          args: [new AddressValue(new Address(address))]
        });
        const proxy = new ProxyNetworkProvider(network.apiAddress, {
          timeout: TIMEOUT
        });

        try {
          const { returnData } = await proxy.queryContract(query);
          const [encoded] = returnData;
          if (encoded == undefined || encoded == '') {
            setClaimedAmount(0);
          } else {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            const value = convertWeiToEgld(
              parseInt(decoded, 16),
              VESTING_TOKEN_DECIMAL
            );
            console.log('value: ', value);
            setClaimedAmount(value);
          }
        } catch (e: any) {
          console.error('Unable to call VM query', e);
        }
      })();

      (async () => {
        const query = new Query({
          address: new Address(VESTING_CONTRACT_ADDRESS),
          func: new ContractFunction('getReclaimableVestingAmount'),
          args: [new AddressValue(new Address(address))]
        });
        const proxy = new ProxyNetworkProvider(network.apiAddress, {
          timeout: TIMEOUT
        });

        try {
          const { returnData } = await proxy.queryContract(query);
          const [encoded] = returnData;
          if (encoded == undefined || encoded == '') {
            setClaimableAmount(0);
          } else {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            const value = convertWeiToEgld(
              parseInt(decoded, 16),
              VESTING_TOKEN_DECIMAL
            );
            console.log('value: ', value);
            setClaimableAmount(value);
          }
        } catch (e: any) {
          console.error('Unable to call VM query', e);
        }
      })();

      (async () => {
        const query = new Query({
          address: new Address(VESTING_CONTRACT_ADDRESS),
          func: new ContractFunction('getNextReclaimableTimestamp'),
          args: [new AddressValue(new Address(address))]
        });
        const proxy = new ProxyNetworkProvider(network.apiAddress, {
          timeout: TIMEOUT
        });

        try {
          const { returnData } = await proxy.queryContract(query);
          const [encoded] = returnData;
          if (encoded == undefined || encoded == '') {
            setNextReclaimableTimestamp(0);
          } else {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            const value = parseInt(decoded, 16);
            setNextReclaimableTimestamp(value);
          }
        } catch (e: any) {
          console.error('Unable to call VM query', e);
        }
      })();
      //==============================

      //=======New Presale===========
      (async () => {
        // token balance in sc
        const query = new Query({
          address: new Address(VESTING_CONTRACT_ADDRESS_1),
          func: new ContractFunction('getUserVestingAmountAll'),
          args: [new AddressValue(new Address(address))]
        });
        const proxy = new ProxyNetworkProvider(network.apiAddress, {
          timeout: TIMEOUT
        });

        try {
          const { returnData } = await proxy.queryContract(query);

          const [encoded] = returnData;
          if (encoded == undefined || encoded == '') {
            setTotalVestingAmount1(0);
          } else {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            const value = convertWeiToEgld(
              parseInt(decoded, 16),
              VESTING_TOKEN_DECIMAL
            );
            console.log('value: ', value);
            setTotalVestingAmount1(value);
          }
        } catch (e: any) {
          console.error('Unable to call VM query', e);
        }
      })();

      (async () => {
        const query = new Query({
          address: new Address(VESTING_CONTRACT_ADDRESS_1),
          func: new ContractFunction('getUserClaimedAmount'),
          args: [new AddressValue(new Address(address))]
        });
        const proxy = new ProxyNetworkProvider(network.apiAddress, {
          timeout: TIMEOUT
        });

        try {
          const { returnData } = await proxy.queryContract(query);
          const [encoded] = returnData;
          if (encoded == undefined || encoded == '') {
            setClaimedAmount1(0);
          } else {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            const value = convertWeiToEgld(
              parseInt(decoded, 16),
              VESTING_TOKEN_DECIMAL
            );
            console.log('value: ', value);
            setClaimedAmount1(value);
          }
        } catch (e: any) {
          console.error('Unable to call VM query', e);
        }
      })();

      (async () => {
        const query = new Query({
          address: new Address(VESTING_CONTRACT_ADDRESS_1),
          func: new ContractFunction('getReclaimableVestingAmount'),
          args: [new AddressValue(new Address(address))]
        });
        const proxy = new ProxyNetworkProvider(network.apiAddress, {
          timeout: TIMEOUT
        });

        try {
          const { returnData } = await proxy.queryContract(query);
          const [encoded] = returnData;
          if (encoded == undefined || encoded == '') {
            setClaimableAmount1(0);
          } else {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            const value = convertWeiToEgld(
              parseInt(decoded, 16),
              VESTING_TOKEN_DECIMAL
            );
            console.log('value: ', value);
            setClaimableAmount1(value);
          }
        } catch (e: any) {
          console.error('Unable to call VM query', e);
        }
      })();

      (async () => {
        const query = new Query({
          address: new Address(VESTING_CONTRACT_ADDRESS_1),
          func: new ContractFunction('getNextReclaimableTimestamp'),
          args: [new AddressValue(new Address(address))]
        });
        const proxy = new ProxyNetworkProvider(network.apiAddress, {
          timeout: TIMEOUT
        });

        try {
          const { returnData } = await proxy.queryContract(query);
          const [encoded] = returnData;
          if (encoded == undefined || encoded == '') {
            setNextReclaimableTimestamp1(0);
          } else {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            const value = parseInt(decoded, 16);
            setNextReclaimableTimestamp1(value);
          }
        } catch (e: any) {
          console.error('Unable to call VM query', e);
        }
      })();
      //==============================
    }
  }, [hasPendingTransactions]);

  async function claimSeedSale(e: any) {
    e.preventDefault();
    const tx = {
      data: 'claim',
      receiver: SEED_SALE_CONTRACT_ADDRESS,
      gasLimit: 60000000,
      value: 0
    };
    await refreshAccount();
    await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Claim transaction',
        errorMessage: 'An error has occured during Claim',
        successMessage: 'Claim transaction successful'
      },
      redirectAfterSign: false
    });
  }
  async function claimSeedSale2(e: any) {
    const tx = {
      data: 'claim',
      receiver: SEED_SALE_CONTRACT2_ADDRESS,
      gasLimit: 60000000,
      value: 0
    };
    await refreshAccount();
    await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Claim transaction',
        errorMessage: 'An error has occured during Claim',
        successMessage: 'Claim transaction successful'
      },
      redirectAfterSign: false
    });
  }

  async function claimPrivateSale(e: any) {
    e.preventDefault();
    const tx = {
      data: 'claim',
      receiver: PRIVATE_SALE_CONTRACT_ADDRESS,
      gasLimit: 60000000,
      value: 0
    };
    await refreshAccount();
    await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Claim transaction',
        errorMessage: 'An error has occured during Claim',
        successMessage: 'Claim transaction successful'
      },
      redirectAfterSign: false
    });
  }

  const handleClaim = async () => {
    const claimTransaction = {
      data: 'claim',
      gasLimit: 30000000,
      receiver: VESTING_CONTRACT_ADDRESS,
      value: 0
    };

    await refreshAccount();
    await sendTransactions({
      transactions: claimTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Claim transaction',
        errorMessage: 'An error has occured during Claim',
        successMessage: 'Claim transaction successful'
      },
      redirectAfterSign: false
    });
  };

  const handleClaim1 = async () => {
    const claimTransaction = {
      data: 'claim',
      gasLimit: 30000000,
      receiver: VESTING_CONTRACT_ADDRESS_1,
      value: 0
    };

    await refreshAccount();
    await sendTransactions({
      transactions: claimTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Claim transaction',
        errorMessage: 'An error has occured during Claim',
        successMessage: 'Claim transaction successful'
      },
      redirectAfterSign: false
    });
  };

  return (
    <div className='container'>
      <div className='main-banner no-overlay'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h3>EVLD</h3>
              <h1>CLAIM</h1>
            </div>
          </div>
        </div>
      </div>

      <div className='col-12'>
        <h3>Public Sale</h3>
      </div>
      <div className='general-content stake-statistics-wrapper'>
        <div className='gc-corners'>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tl'>
            <path
              className='st0'
              fill='#333C54'
              d='M0,45V12.2C0,5.5,5.4,0,12.2,0h124c6.5,0,12.7,3,16.7,8.2L181,45H0z'
            />
          </svg>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tr'>
            <path
              className='st0'
              fill='#333C54'
              d='M181,45V12.2C181,5.5,175.6,0,168.8,0l-124,0c-6.5,0-12.7,3-16.7,8.2L0,45H181z'
            />
          </svg>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-bl'>
            <path
              className='st0'
              fill='#333C54'
              d='M0,0l0,32.8C0,39.5,5.4,45,12.2,45h124c6.5,0,12.7-3,16.7-8.2L181,0L0,0z'
            />
          </svg>
          <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-br'>
            <path
              className='st0'
              fill='#333C54'
              d='M181,0v32.8c0,6.7-5.4,12.2-12.2,12.2h-124c-6.5,0-12.7-3-16.7-8.2L0,0H181z'
            />
          </svg>
        </div>

        <div className='w-100'>
          <div
            className='card bg-dark swap-form stake-body d-flex flex-row'
            aria-controls='positions-list'
            aria-expanded='false'
          >
            <div className='card-body d-flex justify-content-between stake-card-main'>
              <div className='d-flex flex-row align-items-center stake-farm-header'>
                <div className='p-0'>
                  <img
                    src={EvldLogo}
                    className='p-0 token-image'
                    style={{ width: '48px', height: '48px' }}
                  ></img>
                </div>
                <div className='ml-3'>
                  <h6>
                    <b>Total Vesting</b>
                  </h6>
                  <div className='dotted-underline'>
                    <div>
                      <h6>
                        <b>{totalVestingAmount1.toFixed(2)} $EVLD</b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center arrow-column'></div>
              </div>
              <div className='d-flex stake-farm-body'>
                <div className='key'>
                  <h6>Claimed</h6>
                  <div className='value'>
                    <h6>
                      <b>{claimedAmount1.toFixed(2)} $EVLD</b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className='d-flex stake-farm-body'>
                <div className='key'>
                  <h6>Claimable</h6>
                  <div className='value'>
                    <h6>
                      <b>{claimableAmount1.toFixed(2)} $EVLD</b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className='d-flex stake-farm-body'>
                <div className='key'>
                  <h6>Next Unlock</h6>
                  <div className='value'>
                    <h6>
                      <b>
                        {nextReclaimableTimestamp1 != 0 && (
                          <Moment format='MM.DD.YY hh:mm a'>
                            {
                              new Date(
                                parseInt(nextReclaimableTimestamp1 + '000')
                              )
                            }
                          </Moment>
                        )}
                        {/* {nextReclaimableTimestamp != 0
                          ? getDaysFromNow2(nextReclaimableTimestamp) + ' Days'
                          : '  -'} */}
                      </b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className='d-flex stake-farm-body'>
                <div className='key'>
                  <h6>Remaining</h6>
                  <div className='value'>
                    {' '}
                    <h6>
                      <b>
                        {(totalVestingAmount1 - claimedAmount1).toFixed(2)}{' '}
                        $EVLD
                      </b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className='d-flex stake-buttons'>
                {isLoggedIn ? (
                  <button
                    className='main-btn connect-btn w-100'
                    disabled={claimableAmount1 <= 0}
                    onClick={handleClaim1}
                  >
                    <span className='btn-name'>CLAIM</span>
                    <svg
                      width='17'
                      height='10'
                      viewBox='0 0 17 10'
                      className='btn-icon'
                    >
                      <path
                        d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                        fill='white'
                      />
                    </svg>
                    <svg
                      x='0px'
                      y='0px'
                      viewBox='0 0 66 53'
                      className='btn-deco'
                    >
                      <g>
                        <g>
                          <path
                            className='st0'
                            fill='#005DFF'
                            d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                          />
                          <path
                            className='st1'
                            fill='#005DFF'
                            d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                          />
                        </g>
                        <path
                          className='st2'
                          fill='#3F4B6B'
                          d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                        />
                      </g>
                    </svg>
                    <span className='btn-shadow'></span>
                  </button>
                ) : (
                  <Link
                    className='main-btn connect-btn w-100'
                    to={
                      referralId && referralId.length > 0
                        ? `/unlock/${referralId}`
                        : '/unlock'
                    }
                  >
                    <span className='btn-name'>CONNECT</span>
                    <svg
                      width='17'
                      height='10'
                      viewBox='0 0 17 10'
                      className='btn-icon'
                    >
                      <path
                        d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                        fill='white'
                      />
                    </svg>
                    <svg
                      x='0px'
                      y='0px'
                      viewBox='0 0 66 53'
                      className='btn-deco'
                    >
                      <g>
                        <g>
                          <path
                            className='st0'
                            fill='#005DFF'
                            d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                          />
                          <path
                            className='st1'
                            fill='#005DFF'
                            d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                          />
                        </g>
                        <path
                          className='st2'
                          fill='#3F4B6B'
                          d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                        />
                      </g>
                    </svg>
                    <span className='btn-shadow'></span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      {nextReclaimableTimestamp > 0 && (
        <>
          <div className='col-12'>
            <h3>Presale 3</h3>
          </div>
          <div className='general-content stake-statistics-wrapper'>
            <div className='gc-corners'>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tl'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M0,45V12.2C0,5.5,5.4,0,12.2,0h124c6.5,0,12.7,3,16.7,8.2L181,45H0z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tr'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M181,45V12.2C181,5.5,175.6,0,168.8,0l-124,0c-6.5,0-12.7,3-16.7,8.2L0,45H181z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-bl'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M0,0l0,32.8C0,39.5,5.4,45,12.2,45h124c6.5,0,12.7-3,16.7-8.2L181,0L0,0z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-br'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M181,0v32.8c0,6.7-5.4,12.2-12.2,12.2h-124c-6.5,0-12.7-3-16.7-8.2L0,0H181z'
                />
              </svg>
            </div>

            <div className='w-100'>
              <div
                className='card bg-dark swap-form stake-body d-flex flex-row'
                aria-controls='positions-list'
                aria-expanded='false'
              >
                <div className='card-body d-flex justify-content-between stake-card-main'>
                  <div className='d-flex flex-row align-items-center stake-farm-header'>
                    <div className='p-0'>
                      <img
                        src={EvldLogo}
                        className='p-0 token-image'
                        style={{ width: '48px', height: '48px' }}
                      ></img>
                    </div>
                    <div className='ml-3'>
                      <h6>
                        <b>Total Vesting</b>
                      </h6>
                      <div className='dotted-underline'>
                        <div>
                          <h6>
                            <b>{totalVestingAmount.toFixed(2)} $EVLD</b>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center arrow-column'></div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Claimed</h6>
                      <div className='value'>
                        <h6>
                          <b>{claimedAmount.toFixed(2)} $EVLD</b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Claimable</h6>
                      <div className='value'>
                        <h6>
                          <b>{claimableAmount.toFixed(2)} $EVLD</b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Next Unlock</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {nextReclaimableTimestamp != 0 && (
                              <Moment format='MM.DD.YY hh:mm a'>
                                {
                                  new Date(
                                    parseInt(nextReclaimableTimestamp + '000')
                                  )
                                }
                              </Moment>
                            )}
                            {/* {nextReclaimableTimestamp != 0
                          ? getDaysFromNow2(nextReclaimableTimestamp) + ' Days'
                          : '  -'} */}
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Remaining</h6>
                      <div className='value'>
                        {' '}
                        <h6>
                          <b>
                            {(totalVestingAmount - claimedAmount).toFixed(2)}{' '}
                            $EVLD
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-buttons'>
                    {isLoggedIn ? (
                      <button
                        className='main-btn connect-btn w-100'
                        disabled={claimableAmount <= 0}
                        onClick={handleClaim}
                      >
                        <span className='btn-name'>CLAIM</span>
                        <svg
                          width='17'
                          height='10'
                          viewBox='0 0 17 10'
                          className='btn-icon'
                        >
                          <path
                            d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                            fill='white'
                          />
                        </svg>
                        <svg
                          x='0px'
                          y='0px'
                          viewBox='0 0 66 53'
                          className='btn-deco'
                        >
                          <g>
                            <g>
                              <path
                                className='st0'
                                fill='#005DFF'
                                d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                              />
                              <path
                                className='st1'
                                fill='#005DFF'
                                d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                              />
                            </g>
                            <path
                              className='st2'
                              fill='#3F4B6B'
                              d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                            />
                          </g>
                        </svg>
                        <span className='btn-shadow'></span>
                      </button>
                    ) : (
                      <Link
                        className='main-btn connect-btn w-100'
                        to={
                          referralId && referralId.length > 0
                            ? `/unlock/${referralId}`
                            : '/unlock'
                        }
                      >
                        <span className='btn-name'>CONNECT</span>
                        <svg
                          width='17'
                          height='10'
                          viewBox='0 0 17 10'
                          className='btn-icon'
                        >
                          <path
                            d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                            fill='white'
                          />
                        </svg>
                        <svg
                          x='0px'
                          y='0px'
                          viewBox='0 0 66 53'
                          className='btn-deco'
                        >
                          <g>
                            <g>
                              <path
                                className='st0'
                                fill='#005DFF'
                                d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                              />
                              <path
                                className='st1'
                                fill='#005DFF'
                                d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                              />
                            </g>
                            <path
                              className='st2'
                              fill='#3F4B6B'
                              d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                            />
                          </g>
                        </svg>
                        <span className='btn-shadow'></span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      {accountStateSeed2?.currentLockedAmount > 0 && (
        <>
          <div className='col-12'>
            <h3>Presale 2</h3>
          </div>
          <div className='text-center  '>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <h3>seed sale 2/2</h3>
                </div>
              </div>
            </div>
          </div>

          <div className='general-content stake-statistics-wrapper'>
            <div className='gc-corners'>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tl'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M0,45V12.2C0,5.5,5.4,0,12.2,0h124c6.5,0,12.7,3,16.7,8.2L181,45H0z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tr'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M181,45V12.2C181,5.5,175.6,0,168.8,0l-124,0c-6.5,0-12.7,3-16.7,8.2L0,45H181z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-bl'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M0,0l0,32.8C0,39.5,5.4,45,12.2,45h124c6.5,0,12.7-3,16.7-8.2L181,0L0,0z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-br'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M181,0v32.8c0,6.7-5.4,12.2-12.2,12.2h-124c-6.5,0-12.7-3-16.7-8.2L0,0H181z'
                />
              </svg>
            </div>

            <div className='w-100'>
              <div
                className='card bg-dark swap-form stake-body d-flex flex-row'
                aria-controls='positions-list'
                aria-expanded='false'
              >
                <div className='card-body d-flex justify-content-between stake-card-main'>
                  <div className='d-flex flex-row align-items-center stake-farm-header'>
                    <div className='p-0'>
                      <img
                        src={EvldLogo}
                        className='p-0 token-image'
                        style={{ width: '48px', height: '48px' }}
                      ></img>
                    </div>
                    <div className='ml-3'>
                      <h6>
                        <b>Total Vesting</b>
                      </h6>
                      <div className='dotted-underline'>
                        <div>
                          <h6>
                            <b>
                              {accountStateSeed2?.initialLockedAmount?.toFixed(
                                2
                              )}{' '}
                              $EVLD
                            </b>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center arrow-column'></div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Claimed</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStateSeed2?.claimedReleaseAmount?.toFixed(
                              2
                            )}{' '}
                            $EVLD
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Claimable</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStateSeed2.claimableReleaseAmount?.toFixed(
                              2
                            )}{' '}
                            $EVLD
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Next Unlock</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStateSeed2?.nextReleaseTimestamp != 0 && (
                              <Moment format='MM.DD.YY hh:mm a'>
                                {
                                  new Date(
                                    parseInt(
                                      accountStateSeed2?.nextReleaseTimestamp
                                    )
                                  )
                                }
                              </Moment>
                            )}
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Full Unlock</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStateSeed2.currentLockedAmount != 0 && (
                              <Moment format='MM.DD.YY hh:mm a'>
                                {
                                  new Date(
                                    parseInt(
                                      accountStateSeed2.fullReleaseTimestamp
                                    )
                                  )
                                }
                              </Moment>
                            )}
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Locked</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStateSeed2?.currentLockedAmount?.toFixed(2)}{' '}
                            $EVLD
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex stake-buttons'>
                    {isLoggedIn ? (
                      <button
                        className='main-btn connect-btn w-100'
                        disabled={
                          accountStateSeed2?.claimableReleaseAmount <= 0
                        }
                        onClick={claimSeedSale2}
                      >
                        <span className='btn-name'>CLAIM</span>
                        <svg
                          width='17'
                          height='10'
                          viewBox='0 0 17 10'
                          className='btn-icon'
                        >
                          <path
                            d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                            fill='white'
                          />
                        </svg>
                        <svg
                          x='0px'
                          y='0px'
                          viewBox='0 0 66 53'
                          className='btn-deco'
                        >
                          <g>
                            <g>
                              <path
                                className='st0'
                                fill='#005DFF'
                                d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                              />
                              <path
                                className='st1'
                                fill='#005DFF'
                                d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                              />
                            </g>
                            <path
                              className='st2'
                              fill='#3F4B6B'
                              d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                            />
                          </g>
                        </svg>
                        <span className='btn-shadow'></span>
                      </button>
                    ) : (
                      <Link
                        className='main-btn connect-btn w-100'
                        to={
                          referralId && referralId.length > 0
                            ? `/unlock/${referralId}`
                            : '/unlock'
                        }
                      >
                        <span className='btn-name'>CONNECT</span>
                        <svg
                          width='17'
                          height='10'
                          viewBox='0 0 17 10'
                          className='btn-icon'
                        >
                          <path
                            d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                            fill='white'
                          />
                        </svg>
                        <svg
                          x='0px'
                          y='0px'
                          viewBox='0 0 66 53'
                          className='btn-deco'
                        >
                          <g>
                            <g>
                              <path
                                className='st0'
                                fill='#005DFF'
                                d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                              />
                              <path
                                className='st1'
                                fill='#005DFF'
                                d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                              />
                            </g>
                            <path
                              className='st2'
                              fill='#3F4B6B'
                              d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                            />
                          </g>
                        </svg>
                        <span className='btn-shadow'></span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      {accountStateSeed?.currentLockedAmount > 0 && (
        <>
          <div className='col-12'>
            <h3>Presale 1</h3>
          </div>
          <div className='text-center  '>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <h3>seed sale 1/2</h3>
                </div>
              </div>
            </div>
          </div>

          <div className='general-content stake-statistics-wrapper'>
            <div className='gc-corners'>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tl'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M0,45V12.2C0,5.5,5.4,0,12.2,0h124c6.5,0,12.7,3,16.7,8.2L181,45H0z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tr'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M181,45V12.2C181,5.5,175.6,0,168.8,0l-124,0c-6.5,0-12.7,3-16.7,8.2L0,45H181z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-bl'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M0,0l0,32.8C0,39.5,5.4,45,12.2,45h124c6.5,0,12.7-3,16.7-8.2L181,0L0,0z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-br'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M181,0v32.8c0,6.7-5.4,12.2-12.2,12.2h-124c-6.5,0-12.7-3-16.7-8.2L0,0H181z'
                />
              </svg>
            </div>

            <div className='w-100'>
              <div
                className='card bg-dark swap-form stake-body d-flex flex-row'
                aria-controls='positions-list'
                aria-expanded='false'
              >
                <div className='card-body d-flex justify-content-between stake-card-main'>
                  <div className='d-flex flex-row align-items-center stake-farm-header'>
                    <div className='p-0'>
                      <img
                        src={EvldLogo}
                        className='p-0 token-image'
                        style={{ width: '48px', height: '48px' }}
                      ></img>
                    </div>
                    <div className='ml-3'>
                      <h6>
                        <b>Total Vesting</b>
                      </h6>
                      <div className='dotted-underline'>
                        <div>
                          <h6>
                            <b>
                              {accountStateSeed?.initialLockedAmount?.toFixed(
                                2
                              )}{' '}
                              $EVLD
                            </b>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center arrow-column'></div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Claimed</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStateSeed?.claimedReleaseAmount?.toFixed(2)}{' '}
                            $EVLD
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Claimable</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStateSeed.claimableReleaseAmount?.toFixed(
                              2
                            )}{' '}
                            $EVLD
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Next Unlock</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStateSeed?.nextReleaseTimestamp != 0 && (
                              <Moment format='MM.DD.YY hh:mm a'>
                                {
                                  new Date(
                                    parseInt(
                                      accountStateSeed?.nextReleaseTimestamp
                                    )
                                  )
                                }
                              </Moment>
                            )}
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Full Unlock</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStateSeed.currentLockedAmount != 0 && (
                              <Moment format='MM.DD.YY hh:mm a'>
                                {
                                  new Date(
                                    parseInt(
                                      accountStateSeed?.fullReleaseTimestamp
                                    )
                                  )
                                }
                              </Moment>
                            )}
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Locked</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStateSeed?.currentLockedAmount?.toFixed(2)}{' '}
                            $EVLD
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex stake-buttons'>
                    {isLoggedIn ? (
                      <button
                        className='main-btn connect-btn w-100'
                        disabled={accountStateSeed?.claimableReleaseAmount <= 0}
                        onClick={claimSeedSale}
                      >
                        <span className='btn-name'>CLAIM</span>
                        <svg
                          width='17'
                          height='10'
                          viewBox='0 0 17 10'
                          className='btn-icon'
                        >
                          <path
                            d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                            fill='white'
                          />
                        </svg>
                        <svg
                          x='0px'
                          y='0px'
                          viewBox='0 0 66 53'
                          className='btn-deco'
                        >
                          <g>
                            <g>
                              <path
                                className='st0'
                                fill='#005DFF'
                                d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                              />
                              <path
                                className='st1'
                                fill='#005DFF'
                                d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                              />
                            </g>
                            <path
                              className='st2'
                              fill='#3F4B6B'
                              d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                            />
                          </g>
                        </svg>
                        <span className='btn-shadow'></span>
                      </button>
                    ) : (
                      <Link
                        className='main-btn connect-btn w-100'
                        to={
                          referralId && referralId.length > 0
                            ? `/unlock/${referralId}`
                            : '/unlock'
                        }
                      >
                        <span className='btn-name'>CONNECT</span>
                        <svg
                          width='17'
                          height='10'
                          viewBox='0 0 17 10'
                          className='btn-icon'
                        >
                          <path
                            d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                            fill='white'
                          />
                        </svg>
                        <svg
                          x='0px'
                          y='0px'
                          viewBox='0 0 66 53'
                          className='btn-deco'
                        >
                          <g>
                            <g>
                              <path
                                className='st0'
                                fill='#005DFF'
                                d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                              />
                              <path
                                className='st1'
                                fill='#005DFF'
                                d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                              />
                            </g>
                            <path
                              className='st2'
                              fill='#3F4B6B'
                              d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                            />
                          </g>
                        </svg>
                        <span className='btn-shadow'></span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      {accountStatePrivate?.lockedAmount > 0 && (
        <>
          <div className='col-12'>
            <h3>Private Sale</h3>
          </div>
          <div className='text-center  '>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <h3>private sale </h3>
                </div>
              </div>
            </div>
          </div>

          <div className='general-content stake-statistics-wrapper'>
            <div className='gc-corners'>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tl'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M0,45V12.2C0,5.5,5.4,0,12.2,0h124c6.5,0,12.7,3,16.7,8.2L181,45H0z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-tr'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M181,45V12.2C181,5.5,175.6,0,168.8,0l-124,0c-6.5,0-12.7,3-16.7,8.2L0,45H181z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-bl'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M0,0l0,32.8C0,39.5,5.4,45,12.2,45h124c6.5,0,12.7-3,16.7-8.2L181,0L0,0z'
                />
              </svg>
              <svg x='0px' y='0px' viewBox='0 0 181 45' className='gc-br'>
                <path
                  className='st0'
                  fill='#333C54'
                  d='M181,0v32.8c0,6.7-5.4,12.2-12.2,12.2h-124c-6.5,0-12.7-3-16.7-8.2L0,0H181z'
                />
              </svg>
            </div>

            <div className='w-100'>
              <div
                className='card bg-dark swap-form stake-body d-flex flex-row'
                aria-controls='positions-list'
                aria-expanded='false'
              >
                <div className='card-body d-flex justify-content-between stake-card-main'>
                  <div className='d-flex flex-row align-items-center stake-farm-header'>
                    <div className='p-0'>
                      <img
                        src={EvldLogo}
                        className='p-0 token-image'
                        style={{ width: '48px', height: '48px' }}
                      ></img>
                    </div>
                    <div className='ml-3'>
                      <h6>
                        <b>Total Vesting</b>
                      </h6>
                      <div className='dotted-underline'>
                        <div>
                          <h6>
                            <b>
                              {accountStatePrivate?.boughtAmount?.toFixed(2)}{' '}
                              $EVLD
                            </b>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center arrow-column'></div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Claimed</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {(
                              accountStatePrivate?.boughtAmount -
                              accountStatePrivate?.lockedAmount
                            ).toFixed(2)}{' '}
                            $EVLD
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Claimable</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStatePrivate.claimableAmount?.toFixed(2)}{' '}
                            $EVLD
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Next Unlock</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStatePrivate?.lockedAmount != 0 && (
                              <Moment format='MM.DD.YY hh:mm a'>
                                {
                                  new Date(
                                    parseInt(daysFromNextUnlock_1.toString())
                                  )
                                }
                              </Moment>
                            )}
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Full Unlock</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStatePrivate?.lockedAmount != 0 && (
                              <Moment format='MM.DD.YY hh:mm a'>
                                {
                                  new Date(
                                    parseInt(daysFromFullUnlock_1.toString())
                                  )
                                }
                              </Moment>
                            )}
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex stake-farm-body'>
                    <div className='key'>
                      <h6>Locked</h6>
                      <div className='value'>
                        <h6>
                          <b>
                            {accountStatePrivate?.lockedAmount?.toFixed(2)}{' '}
                            $EVLD
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex stake-buttons'>
                    {isLoggedIn ? (
                      <button
                        className='main-btn connect-btn w-100'
                        disabled={accountStatePrivate.claimableAmount <= 0}
                        onClick={claimPrivateSale}
                      >
                        <span className='btn-name'>CLAIM</span>
                        <svg
                          width='17'
                          height='10'
                          viewBox='0 0 17 10'
                          className='btn-icon'
                        >
                          <path
                            d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                            fill='white'
                          />
                        </svg>
                        <svg
                          x='0px'
                          y='0px'
                          viewBox='0 0 66 53'
                          className='btn-deco'
                        >
                          <g>
                            <g>
                              <path
                                className='st0'
                                fill='#005DFF'
                                d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                              />
                              <path
                                className='st1'
                                fill='#005DFF'
                                d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                              />
                            </g>
                            <path
                              className='st2'
                              fill='#3F4B6B'
                              d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                            />
                          </g>
                        </svg>
                        <span className='btn-shadow'></span>
                      </button>
                    ) : (
                      <Link
                        className='main-btn connect-btn w-100'
                        to={
                          referralId && referralId.length > 0
                            ? `/unlock/${referralId}`
                            : '/unlock'
                        }
                      >
                        <span className='btn-name'>CONNECT</span>
                        <svg
                          width='17'
                          height='10'
                          viewBox='0 0 17 10'
                          className='btn-icon'
                        >
                          <path
                            d='M15.9219 4.29335L11.8893 0.566788C11.333 0.055299 10.4292 0.493718 10.4292 1.29749V2.61274C10.4292 3.12423 10.012 3.56265 9.52532 3.56265H0.903863C0.417167 3.48958 0 3.928 0 4.43949V5.46247C0 5.97396 0.417167 6.41238 0.903863 6.41238H9.52532C10.012 6.41238 10.4292 6.8508 10.4292 7.36228V8.67754C10.4292 9.48131 11.333 9.91973 11.8893 9.40824L15.9219 5.68168C16.3391 5.31633 16.3391 4.6587 15.9219 4.29335Z'
                            fill='white'
                          />
                        </svg>
                        <svg
                          x='0px'
                          y='0px'
                          viewBox='0 0 66 53'
                          className='btn-deco'
                        >
                          <g>
                            <g>
                              <path
                                className='st0'
                                fill='#005DFF'
                                d='M1.5,51.5v-50h6c2.5,0,4.9,0.8,6.9,2.2l44.7,32.4c2.2,1.6,3.5,4.2,3.5,6.9c0,4.7-3.7,8.5-8.3,8.5H1.5z'
                              />
                              <path
                                className='st1'
                                fill='#005DFF'
                                d='M7.5,3c2.2,0,4.3,0.7,6,2l44.7,32.4c1.8,1.3,2.9,3.4,2.9,5.7c0,3.8-3.1,7-6.8,7H3V3H7.5 M7.5,0H0v53h54.2
        c5.4,0,9.8-4.5,9.8-10c0-3.2-1.5-6.2-4.1-8.1L15.3,2.5C13,0.9,10.3,0,7.5,0L7.5,0z'
                              />
                            </g>
                            <path
                              className='st2'
                              fill='#3F4B6B'
                              d='M66,31L22.2,0h29.2C63.4,0,66.2,9.7,66,14.6V31z'
                            />
                          </g>
                        </svg>
                        <span className='btn-shadow'></span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
