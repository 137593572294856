export const dAppName = 'example Dapp';

// // Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';

export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.elrond.com';
// /**
//  * Calls to these domains will use `nativeAuth` Baerer token
//  asd*/
export const sampleAuthenticatedDomains = [TOOLS_API_URL];

export const isDev = false;
export const ENVIRONMENT = isDev ? 'devnet' : 'mainnet';
export const GATEWAY = isDev
  ? 'https://devnet-api.multiversx.com'
  : 'https://api.multiversx.com';

export const TOKEN_ID = isDev ? 'VEST-67e27b' : 'EVLD-43f56f';
export const TOKEN_DECIMAL = 6;

export const TIMEOUT = 6000;

export const WEGLD_ID = isDev ? 'WEGLD-a28c59' : 'WEGLD-bd4d79';
export const USDC_ID = isDev ? 'USDC-350c4e' : 'USDC-c76f1f';

//CONTRACT ADDRESSES
export const VESTING_CONTRACT_ADDRESS = isDev
  ? 'erd1qqqqqqqqqqqqqpgqpdsv9hp3n7xmj0cqh50wr9auqtmrjp77q4tszgs0le'
  : 'erd1qqqqqqqqqqqqqpgq2u7ey88yql262kalthlhf023xgd88tgpuslsw6c5ap';
export const PRIVATE_SALE_CONTRACT_ADDRESS =
  'erd1qqqqqqqqqqqqqpgq369l45dtwkkwttxtw252zrm9c0ke6qp0vc9sj0y4wy';
export const SEED_SALE_CONTRACT_ADDRESS =
  'erd1qqqqqqqqqqqqqpgqwrgwyc0ngwt8fqh7tdsx2295exk9fewzudlsa8n0w9';
export const SEED_SALE_CONTRACT2_ADDRESS =
  'erd1qqqqqqqqqqqqqpgqltky5lw3hpzpskn75ad6pyulu6rmaqtluslsdy3ult';

export const VESTING_CONTRACT_ADDRESS_1 = isDev
  ? 'erd1qqqqqqqqqqqqqpgqlgj5qg3dxyhzs97qsyffxmc0fv37lajx5zvsqdq0qv'
  : 'erd1qqqqqqqqqqqqqpgqlupjzc3lm9a3vvzg543hrfpfdngvdxzvusls9r9g5v';
//================
export const SEED_SALE_CONTRACT_ABI_URL = './abi';
export const PRIVATE_SALE_CONTRACT_ABI_URL = './abi';
export const TOTAL_RELEASE_COUNT = 18;
export const VESTING_CONTRACT_NAME = 'Dapp';
export const VESTING_TOKEN_DECIMAL = 6;
export const TOTAL_MILESTONE_AMOUNT = 1086956;
export const MAX_REFERRAL_COUNT = 10;
export const EVLD_SALE_PRICE = 0.0184;
export const COUNTDOWN_FINISHED_MESSAGE = 'END OF SALES';

export const RELEASE_TIMESTAMPS_1 = [
  1672160400000, 1674838800000, 1677517200000, 1679932800000, 1682611200000,
  1685203200000, 1687881600000, 1690473600000, 1693152000000, 1695830400000,
  1698422400000, 1701104400000, 1703696400000, 1706374800000, 1709053200000,
  1711558800000, 1714233600000, 1716825600000
];

export const RELEASE_TIMESTAMPS_2 = [
  1672160400000, 1674838800000, 1677517200000, 1679932800000, 1682611200000,
  1685203200000, 1687881600000, 1690473600000, 1693152000000, 1695830400000,
  1698422400000, 1701104400000, 1703696400000, 1706374800000, 1709053200000,
  1711558800000, 1714233600000, 1716825600000
];
