import egldLogo from '../assets/logos/egld-logo.png';
import usdcCLogo from '../assets/logos/usdc-logo.svg';
import ashLogo from '../assets/logos/ash-logo.svg';
import ofeLogo from '../assets/logos/ofe-logo.svg';
import bhatLogo from '../assets/logos/bhat-logo.svg';
import crtLogo from '../assets/logos/crt-logo.svg';
import cruLogo from '../assets/logos/cru-logo.svg';
import qwtLogo from '../assets/logos/qwt-logo.svg';
import rideLogo from '../assets/logos/ride-logo.svg';
import utkLogo from '../assets/logos/utk-logo.svg';
import zpayLogo from '../assets/logos/zpay-logo.svg';
import evldLogo from '../assets/logos/evld-logo.svg';
import htmLogo from '../assets/logos/htm-logo.svg';

import { isDev } from 'config';

export const SECOND_IN_MILLI = 1000;
export const TIMEOUT = 5000;
export const ONE_DAY_IN_SECONDS = 24 * 3600;

export const paymentTokens = [
  {
    logo: egldLogo,
    symbol: 'EGLD',
    decimal: 18,
    tokenId: 'EGLD'
  },
  {
    logo: usdcCLogo,
    symbol: 'USDC',
    decimal: 6,
    tokenId: isDev ? 'USDC-350c4e' : 'USDC-c76f1f'
  },
  {
    logo: ashLogo,
    symbol: 'ASH',
    decimal: 18,
    tokenId: isDev ? 'ASH-e3d1b7' : 'ASH-a642d1'
  },
  {
    logo: bhatLogo,
    symbol: 'BHAT',
    decimal: 18,
    tokenId: 'BHAT-c1fde3'
  },
  {
    logo: crtLogo,
    symbol: 'CRT',
    decimal: 18,
    tokenId: 'CRT-52decf'
  },
  {
    logo: cruLogo,
    symbol: 'CRU',
    decimal: 18,
    tokenId: 'CRU-a5f4aa'
  },
  {
    logo: qwtLogo,
    symbol: 'QWT',
    decimal: 6,
    tokenId: 'QWT-46ac01'
  },
  {
    logo: rideLogo,
    symbol: 'RIDE',
    decimal: 18,
    tokenId: 'RIDE-7d18e9'
  },
  {
    logo: utkLogo,
    symbol: 'UTK',
    decimal: 18,
    tokenId: 'UTK-2f80e9'
  },
  {
    logo: zpayLogo,
    symbol: 'ZPAY',
    decimal: 18,
    tokenId: 'ZPAY-247875'
  },
  {
    logo: htmLogo,
    symbol: 'HTM',
    decimal: 18,
    tokenId: 'HTM-f51d55'
  }
];

export const wrapContracts = {
  Wrap0: {
    address: isDev
      ? 'erd1qqqqqqqqqqqqqpgqqkwzsxkjc83vlfex9dmznwm7tjvxlqqkpauqx0n782'
      : 'erd1qqqqqqqqqqqqqpgqvc7gdl0p4s97guh498wgz75k8sav6sjfjlwqh679jy'
  },
  Wrap1: {
    address: isDev
      ? 'erd1qqqqqqqqqqqqqpgqpv09kfzry5y4sj05udcngesat07umyj70n4sa2c0rp'
      : 'erd1qqqqqqqqqqqqqpgqhe8t5jewej70zupmh44jurgn29psua5l2jps3ntjj3'
  },
  Wrap2: {
    address: isDev
      ? 'erd1qqqqqqqqqqqqqpgqvn9ew0wwn7a3pk053ezex98497hd4exqdg0q8v2e0c'
      : 'erd1qqqqqqqqqqqqqpgqmuk0q2saj0mgutxm4teywre6dl8wqf58xamqdrukln'
  }
};
